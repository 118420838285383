<template>
  <div>
    <div class="lesson-content">
      <b-card>
        <!-- Product Details -->
        <b-card-body>
          <div class="row">
            <div class="col-sm-8 order-2 order-sm-1">
              <h1 class="mb-3">
                {{ product.title }}
              </h1>
              <b-card-text v-html="product.description">
                {{ product.description }}
              </b-card-text>
            </div>
            <div class="col-sm-4 order-1 order-sm-2">
              <div class="item-wrapper">
                <img :src="product.thumbnail" alt="course image" class="course-image" style="max-width: 100%"/>
              </div>
            </div>
          </div>
          <span class="details-bottom"/>
        </b-card-body>
      </b-card>

      <!-- Subscriptions -->
      <section class="grid-view row" :class="{'border-danger': subscribeError}">
        <div
            v-for="(subscription, index) in product.subscription_types" :value="subscription.value"
            :key="subscription.value"
            class="col-sm col-xs-12"
        >
          <b-card
              class="ecommerce-card position-relative pb-2"
              :class="{ 'subscribe-choose': chosenSubscription === subscription.value }"
              no-body
          >
            <!--<img
                :src="freeStickerImg"
                alt="free-sticker"
                class="free-sticker"
                v-if="product.price == 0"
            >-->
            <div class="item-img text-center">
                <b-img
                    :alt="`${product.name}-${product.id}`"
                    fluid
                    class="card-img-top"
                    :src="`${product.thumbnail}`"
                />
            </div>

            <!-- Subscription Details -->
            <b-card-body>
              <div class="item-wrapper">
                <div>
                  <h3 class="pb-2">{{ product.title }} - {{subscription.name}}</h3>
                  <!-- price info -->
                  <div>
                    <h4 class="item-price mt-1" v-if="product.type === 'course'">
                      {{ subscription.price.toFixed(2) }} zł
                    </h4>
                    <h6
                        v-if="subscription.sale_price && product.type === 'course'"
                        class="item-price-sale"
                    >
                      {{ subscription.sale_price.toFixed(2) }} zł
                    </h6>
                  </div>
                </div>
              </div>
              <b-card-text class="">
                {{ product.short_description }}
              </b-card-text>
            </b-card-body>

            <!-- Subscription Action -->
            <div class="item-options text-center">
              <div class="item-wrapper">
                <div class="item-cost">
                  <h4 class="item-price">
                    {{ subscription.price }} zł
                  </h4>
                </div>
              </div>
              <b-button
                  variant="primary"
                  tag="a"
                  class="btn-cart"
                  @click="chooseSubscription(subscription)"
              >
                <feather-icon
                    icon="ShoppingCartIcon"
                    class="mr-50"
                />
                <span>Wybierz</span>
              </b-button>
              <b-button
                  variant="primary"
                  class="btn-cart checked-item"
                  @click="unchooseSubscription()"
              >
                <span>✓</span>
              </b-button>
            </div>
          </b-card>
        </div>
      </section>
    </div>

    <!-- Order Form -->
    <div class="subscribe-block">
        <e-commerce-checkout-step-address
            :address-details="checkoutDetails.address"
            :reg="sreg"
            :reg2="sreg2"
            ref="orderForm"
        />
    </div>

    <!-- Payment Block -->
    <div class="payment-card">
      <!-- Card form -->
      <PayuSecureForm ref="cardForm" />

      <!-- Pay Button -->
      <div class="d-flex justify-content-center bd-highlight mb-3">
        <b-button
            variant="primary"
            block
            type="submit"
            @click="Submit"
            class="w-auto"
        >
          Zapłać kartą
        </b-button>
      </div>
      <div v-if="productCanSell">
        <p class="text-center">lub</p>
        <div class="d-flex justify-content-center bd-highlight mb-3">
          <button type="submit" class="btn btn-outline-primary" @click="SubmitStandart"> Standardowa płatność przelewem</button>
        </div>
      </div>

    </div>

  </div>
</template>

<script>
import {localize} from "vee-validate";
import Polish from 'vee-validate/dist/locale/pl'
import vSelect from 'vue-select'
import Cleave from 'vue-cleave-component'
import PayuSecureForm from "./PayuSecureForm"
import ECommerceCheckoutStepAddress from './shop/checkout/ECommerceCheckoutStepAddress.vue'

import {
  BButton,
  BCard,
  BCardBody,
  BCardHeader,
  BCardText,
  BCardTitle,
  BCol,
  BForm, BFormCheckbox,
  BFormGroup,
  BFormInput, BFormInvalidFeedback, BFormSelect, BFormTextarea,
  BRow,
  BImg
} from "bootstrap-vue";


export default {
  name: 'Subscription',
  components: {
    // BSV
    BForm,
    BCard,
    BCardHeader,
    BCardTitle,
    BCardText,
    BCardBody,
    BRow,
    BCol,
    BFormGroup,
    BFormInput,
    BFormSelect,
    BFormTextarea,
    BButton,
    BFormInvalidFeedback,
    BFormCheckbox,
    Cleave,
    PayuSecureForm,
    BImg,
    // order form
    ECommerceCheckoutStepAddress,
    // 3rd party
    vSelect,
  },

  data() {
    return {
      product: {
        type: Object
      },
      chosenSubscription: null,
      locale: 'pl',
      checkoutDetails: {
        address: {
          first_name: '',
          last_name: '',
          address: '',
          address_2: '',
          post_code: '',
          city: '',
          country: '',
          is_company: false,
          company_name: '',
          nip: '',
        },
      },
      sreg: {
        type: Object,
        required: true,
      },
      sreg2: {
        type: Object,
        required: true,
      },
      subscribeError: false,
    }
  },
  computed: {
    productCanSell(){
      return this.product && this.product.subscription_sell === 1;
    },
  },
  methods: {
    async fetchCourse(slug) {
      return this.$http.get(`${this.$store.state.apiDomain}/api/courses/${slug}`).then(response => {
        return response.data;
      });
    },
    chooseSubscription(subscription) {
      this.chosenSubscription = subscription.value;
      this.subscribeError = false;
    },
    unchooseSubscription() {
      this.chosenSubscription = null;
    },
    setOrderData() {
      let orderData = this.$refs.orderForm.addressDetails;
      orderData['course'] = this.product.id;
      orderData['subscription_type'] = this.chosenSubscription;
      this.$refs.cardForm.orderData = orderData;
    },
     async checkOrderForm() {
      const isValid = await this.$refs.orderForm.refFormObserver.validate();
      if (isValid) {
        if (this.$refs.orderForm.reg === true && this.$refs.orderForm.reg2 === true
            && this.$refs.orderForm.addressDetails.city
            && this.$refs.orderForm.addressDetails.country
            && this.$refs.orderForm.addressDetails.post_code
            && this.$refs.orderForm.addressDetails.address
            && (!localStorage.accessToken && this.$refs.orderForm.addressDetails.email || localStorage.accessToken)
        ) {
          this.setOrderData();

          return true;
        }
      }
      this.$refs.orderForm.scrollToError();

      return false;
    },
    async validate(){
      if (!this.chosenSubscription) {
        this.subscribeError = true;
        this.scrollToSubscribe();

        return false;
      }

      return this.checkOrderForm();
    },
    scrollToSubscribe() {
      setTimeout(function() {
        const el = document.querySelector(".details-bottom");
        el?.scrollIntoView();
      },300);
    },
    async Submit() {
      if(await this.validate()) {
        this.$refs.cardForm.$refs.submitCardButton.click();
      }
    },
    async SubmitStandart() {
      if(await this.validate()) {
        this.$refs.cardForm.$refs.purcacheButton.click();
      }
    },
  },
  async created() {
    this.product = await this.fetchCourse(this.$route.params.slug);
    localize(this.locale, {
      messages: Polish.messages,
      attributes: {
        email: 'E-mail',
        password: 'Hasło',
      },
    })
  },
}
</script>

<style>

.checked-item{
  display: none!important;
}
.subscribe-choose {
  border: 3px solid #d23d24;
}
.subscribe-choose .checked-item{
  display: initial!important;
}
.subscribe-choose a.btn span,
.subscribe-choose a.btn.btn-cart.btn-primary,
.subscribe-block .btn.btn-primary.btn-block{
  display: none!important;
}


</style>
